// Only if targeting older browser
// Check docs/transforms.md for details
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './screens/App/App'

export default function Bootstrap (): React.ReactElement {
  return (
    <Router>
      <App />
    </Router>
  )
}

ReactDOM.render(<Bootstrap />, window.document.getElementById('root'))
