import { createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#2a3042',
      dark: '#222736',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
})

export default lightTheme
