import React from 'react'
const DeviIcon: React.FC = () => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='100' height='auto' viewBox='0 0 278.774 109.38'>
        <defs>
          <clipPath id='clip-path'>
            <rect id='Rechteck_124' data-name='Rechteck 124' width='278.774' height='109.38' fill='#fff' />
          </clipPath>
        </defs>
        <g id='devi_darkmode' transform='translate(-165)'>
          <g id='Gruppe_186' data-name='Gruppe 186' transform='translate(165)' clipPath='url(#clip-path)'>
            <path id='Pfad_524' data-name='Pfad 524' d='M485.688,248.337a10.88,10.88,0,0,0,9.425-5.807V234.6a10.964,10.964,0,0,0-9.425-5.807c-4.985,0-9.018,4.031-9.018,9.7,0,5.808,4.032,9.839,9.018,9.839m9.425,1.909a13.6,13.6,0,0,1-11.748,6.9c-9.091,0-16.259-7.93-16.259-18.649,0-10.519,7.169-18.449,16.259-18.449a13.68,13.68,0,0,1,11.748,6.835v-22h9.5V256.12h-9.5Z' transform='translate(-336.888 -147.768)' fill='#a1a7b2' />
            <path id='Pfad_525' data-name='Pfad 525' d='M633.1,273.634h18.028a8.4,8.4,0,0,0-8.67-6.494,9.366,9.366,0,0,0-9.358,6.494m9.425,22.747c-10.66,0-18.516-8-18.516-18.789a18.155,18.155,0,0,1,18.375-18.309c11.821,0,18.108,9.772,18.108,19.811h-27.94c.681,5.673,4.572,9.091,9.972,9.091,3.758,0,7.375-1.915,8.81-5.126h8.677c-1.842,8.2-9.084,13.323-17.488,13.323' transform='translate(-450.049 -187.002)' fill='#a1a7b2' />
            <path id='Pfad_526' data-name='Pfad 526' d='M771.472,297.841l-8.67-35.109h9.831l5.534,27.046h2.663l5.533-27.046h9.906l-8.67,35.109Z' transform='translate(-550.152 -189.489)' fill='#a1a7b2' />
            <rect id='Rechteck_123' data-name='Rechteck 123' width='9.772' height='35.109' transform='translate(251.206 73.243)' fill='#a1a7b2' />
            <path id='Pfad_527' data-name='Pfad 527' d='M964.865,347.849a5.69,5.69,0,1,1-5.691,5.69,5.69,5.69,0,0,1,5.691-5.69' transform='translate(-691.781 -250.878)' fill='#56729d' />
            <path id='Pfad_528' data-name='Pfad 528' d='M219.136,347.849a5.69,5.69,0,1,0,5.691,5.691,5.691,5.691,0,0,0-5.691-5.691' transform='translate(-153.942 -250.878)' fill='#56729d' />
            <path id='Pfad_529' data-name='Pfad 529' d='M120.176,103.711c-7.606-11.879-10.971-31.9-10.267-43.941-6.863-8.389-17.16-12.144-21.428-13.42-.6,3.7-1.446,10.863-1,15.782,5.816,14.521,17.949,32.2,32.692,41.579' transform='translate(-63.005 -33.429)' fill='#56729d' />
            <path id='Pfad_530' data-name='Pfad 530' d='M197.688,73.8c9.152-9.518,13.215-28.585,13.786-40.173C212.663,18.67,208.439,15.81,197.688,0,186.938,15.81,182.714,18.67,183.9,33.629c.571,11.587,4.634,30.654,13.786,40.173' transform='translate(-132.495 0.001)' fill='#56729d' />
            <path id='Pfad_531' data-name='Pfad 531' d='M56.936,148.994c-16.69-10.081-28.788-26.772-36.359-44.5C13.663,102,6.341,101.976,0,102.369c1.266,5.986,5.287,21.713,14.467,31.764,11.077,10.472,27.56,14.411,42.47,14.861' transform='translate(0 -73.696)' fill='#434f64' />
            <path id='Pfad_532' data-name='Pfad 532' d='M262.636,103.711c14.743-9.384,26.876-27.058,32.692-41.579.448-4.918-.4-12.078-1-15.782-4.267,1.276-14.563,5.03-21.428,13.42.7,12.042-2.661,32.062-10.267,43.941' transform='translate(-189.42 -33.429)' fill='#56729d' />
            <path id='Pfad_533' data-name='Pfad 533' d='M133.65,254.376a76.967,76.967,0,0,1-38.9,6.219c-2.537.249-5.1.381-7.656.381a76.57,76.57,0,0,1-31.24-6.6c-4.606-2.523-10.062-4.444-14.872-1.657l.793.579a89.96,89.96,0,0,0,105.948,0l.795-.579c-4.811-2.787-10.267-.866-14.872,1.657' transform='translate(-29.56 -181.278)' fill='#56729d' />
            <path id='Pfad_534' data-name='Pfad 534' d='M263.478,148.994c14.909-.45,31.393-4.39,42.47-14.861,9.18-10.051,13.2-25.778,14.466-31.764-6.34-.394-13.662-.374-20.577,2.126-7.571,17.727-19.668,34.418-36.359,44.5' transform='translate(-190.027 -73.696)' fill='#434f64' />
          </g>
        </g>
      </svg>
    </>
  )
}

export default DeviIcon
