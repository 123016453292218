import React from 'react'
import { AppBar, Box, CssBaseline, Link, Toolbar, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import lightTheme from '../../shared/themes/LightTheme'
import { makeStyles } from '@mui/styles'
import DeviIcon from '../../shared/Icons/DeviIcon'

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 100,
  },
  cardInner: {
    width: '100%',
    height: 'auto',
    backgroundColor: lightTheme.palette.primary.dark,
    position: 'relative',
  },
  card: {
    width: '40%',
    cursor: 'pointer',
    font: 'Roboto',
  },
  headline: {
    color: '#d9d9d9',
    fontSize: '18px',
    marginTop: '20px',
  },
}),
)

const App: React.FC = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' color='primary'>
          <Toolbar>
            <Box className={classes.logo}>
              <DeviIcon />
            </Box>
            <Box width={20} />
          </Toolbar>
        </AppBar>
        <Box sx={{
          width: '100%',
          height: '100vh',
          backgroundColor: lightTheme.palette.primary.dark,
          display: 'flex',
          flexWrap: 'wrap',
          padding: '20px',
          alignContent: 'flex-start',
        }}
        >
          <Box sx={{ width: '100%', marginTop: '100px' }}>
            <Typography className={classes.headline} sx={{ fontSize: '30px' }}>Willkommen im Demosystem,</Typography>
            <Typography className={classes.headline}>Bitte wählen Sie aus einem der verfügbaren Szenarios: </Typography>
          </Box>
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '100px',
          }}
          >
            <Box className={classes.card}>
              <Box className={classes.cardInner}>
                <Link href='https://products.devi.3m5.de' underline='none'>
                  <img src='/products.png' style={{ width: '100%', height: 'auto' }} />
                </Link>
                <Typography className={classes.headline}>Produktsortiment</Typography>
              </Box>
            </Box>
            <Box className={classes.card} sx={{ cursor: 'pointer' }}>
              <Box className={classes.cardInner}>
                {/* <Typography sx={{ position: 'absolute', transform: 'rotate(-30deg)', top: '40%', left: '28%', fontSize: '2em', color: lightTheme.palette.error.main, fontWeight: 'bold' }}>Coming soon...</Typography>
                <img src='/products.png' style={{ width: '100%', height: 'auto' }} /> */}
                <Link href='https://crm.devi.3m5.de' underline='none'>
                  <img src='/crm.png' style={{ width: '100%', height: 'auto' }} />
                </Link>
                <Typography className={classes.headline}>Mitgliederverwaltung</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App
